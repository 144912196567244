import React, { useContext, useEffect, useState } from 'react';
import config from '../../../config';
import { AuthContext } from '../../../context/AllContext';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import ThreeRadioSelection from '@/components/ThreeRadioSelection';
import DashboardSidebar from '../components/DashboardSidebar';
import Header from '../../../components/Header';
import Card from '../components/Card';
import PatientSelect from './PatientSelect';
import NewPatientModal from './NewPatientModal';
import EditPatientModal from './EditPatientModal';
import ServiceSelect from './ServiceSelect';
import AppointmentCreate from './AppointmentCreate';
import {
	changePatientDetailsInBookingData,
	fetchAvailabilityData,
	fetchPatients,
	handleAddService,
	handleDateTimeLocationSelection,
	handleEditSubmit,
	handleNewPatientSubmit,
	updateBookingDetails,
} from './AppointmentHelpers';
import { getServices } from '../../HealthTestsAndSpecialists/helpers/getData';
import CustomiseMarkersModal from './CustomiseMarkersModal';
import PhlebotomyBooking from './PhlebotomyBooking';

const Appointments = () => {
	const [loading, setLoading] = useState(false);
	const [services, setServices] = useState(null);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedService, setSelectedService] = useState(null);
	const [selectedPatientValue, setSelectedPatientValue] = useState('');
	const [selectedServiceValue, setSelectedServiceValue] = useState('');
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedTime, setSelectedTime] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editedPatient, setEditedPatient] = useState({});
	const [customisedMarkersForm, setCustomisedMarkersForm] = useState({});
	const [customiseMarkersBookingItemId, setCustomiseMarkersBookingItemId] = useState({});
	const [showAddModal, setShowAddModal] = useState(false);
	const [showCustomiseMarkersModal, setShowCustomiseMarkersModal] = useState(false);
	const [availabilityData, setAvailabilityData] = useState({});
	const [existingPatients, setExistingPatients] = useState([]);
	const [continueBtndisabled, setContinueBtndisabled] = useState(true);
	const [requiredDuration, setRequiredDuration] = useState(30);
	const [phlebotomyBooking, setPhlebotomyBooking] = useState(true);
	const [bookingRequestSent, setBookingRequestSent] = useState(false);
	const [clearSelectedTime, setClearSelectedTime] = useState(false);
	const [clearSelectedDate, setClearSelectedDate] = useState(false);

	const [newPatient, setNewPatient] = useState({
		nameTitle: '',
		firstName: '',
		lastName: '',
		gender: '',
		dateOfBirth: '',
		phoneNumber: '',
		company: '',
		occupation: '',
		address_line_1: '',
		city: '',
		postcode: '',
		country: '',
		address_type: '',
		emailAddress: '',
	});

	const { user } = useContext(AuthContext);
	const {
		user: { user_id: userID },
	} = useContext(AuthContext);
	const { selectedLocationCategory, bookingData, setBookingData } = useContext(BookingContext);
	const [selectedBookingOption, setSelectedBookingOption] = useState('bloodTests'); // Default to 'bloodTests'

	useEffect(() => {
		const apiUrl = `${config.REACT_APP_API_URL}`;
		fetchPatients(userID, apiUrl).then((data) => setExistingPatients(data));
	}, [userID]);

	useEffect(() => {
		setLoading(true);
		const callback = (data) => {
			setServices(data);
			setLoading(false);
		};

		getServices(callback).catch((error) => {
			console.error('Error fetching services:', error);
			setLoading(false);
		});
	}, []);

	// Handlers for patient and service selection
	const handlePatientChange = (event) => {
		setSelectedPatientValue(event.target.value);
		const patient = existingPatients.find((p) => {
			return Number(p.record_id) === Number(event.target.value);
		});
		setSelectedPatient(patient);
		onPatientChange(patient);
	};

	const handleServiceChange = (event) => {
		setSelectedServiceValue(event.target.value);
		const service = services?.find((s) => s.service.service_id.toString() === event.target.value);
		setSelectedService(service);
	};

	// Handlers for modals
	const openAddModal = () => {
		setShowAddModal(true);
	};
	const openEditModal = () => {
		setEditedPatient(selectedPatient ? selectedPatient : {});
		setShowEditModal(true);
	};
	const openCustomiseMarkersModal = (bookingItemId) => {
		setCustomiseMarkersBookingItemId(bookingItemId);
		setShowCustomiseMarkersModal(true);
	};
	const closeModal = () => {
		setShowAddModal(false);
		setShowEditModal(false);
		setShowCustomiseMarkersModal(false);
	};

	// Handlers for new and edited patient forms
	const onSubmitNewPatient = async (e) => {
		e.preventDefault();
		await handleNewPatientSubmit(
			newPatient,
			userID,
			config.REACT_APP_API_URL,
			() => {
				setShowAddModal(false);
				fetchPatients(userID, config.REACT_APP_API_URL).then((data) => setExistingPatients(data));
			},
			(error) => console.error('Error adding patient:', error)
		);
	};

	const onSubmitEditedPatient = async (e) => {
		e.preventDefault();
		await handleEditSubmit(
			config.REACT_APP_API_URL,
			userID,
			editedPatient,
			() => {
				setShowEditModal(false);
				fetchPatients(userID, config.REACT_APP_API_URL).then((data) => setExistingPatients(data));
			},
			(error) => console.error('Error editing patient:', error)
		);
	};

	const onSubmitCustomisedMarkers = (updatedMarkers) => {
		updateCustomisedMarkersInBookingData(updatedMarkers, customiseMarkersBookingItemId);
		setShowCustomiseMarkersModal(false);
	};

	const updateCustomisedMarkersInBookingData = (customisedMarkers, targetBookingId) => {
		setBookingData((prevBookingData) => {
			return prevBookingData.map((bookingItem) => {
				if (bookingItem.booking.bookingId === targetBookingId) {
					return {
						...bookingItem,
						service: {
							...bookingItem.service,
							customisedMarkers: {
								...customisedMarkers,
							},
						},
					};
				}
				return bookingItem;
			});
		});
	};

	const handleNewPatientChange = (e) => {
		setNewPatient((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const handleEditChange = (e) => {
		if (e.target.name === 'date_of_birth') {
			// Convert ISO date string to 'YYYY-MM-DD' format for the date input
			const formattedDate = e.target.value.split('T')[0];
			setEditedPatient({ ...editedPatient, [e.target.name]: formattedDate });
		} else {
			setEditedPatient({ ...editedPatient, [e.target.name]: e.target.value });
		}
	};

	const handleCustomiseChange = (e) => {
		setCustomisedMarkersForm((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	// Handlers for updating booking details
	const onUpdateBookingDetails = () => {
		updateBookingDetails(bookingData, selectedDate, selectedTime, selectedLocation, setBookingData);
	};

	const onPatientChange = (newPatient) => {
		changePatientDetailsInBookingData(bookingData, newPatient, setBookingData);
	};

	// Additional handlers for location, date, and time
	const locationCategoryIds = {
		London: 1,
		Edinburgh: 2,
		Manchester: 3,
		'Home Visit': 5,
		Virtual: 6,
		Glasgow: 7,
		Cardiff: 9,
		Belfast: 10,
		Cambridge: 11,
	};

	useEffect(() => {
		if (selectedLocation) {
			const locationId = locationCategoryIds[selectedLocation];
			if (!locationId) return;

			fetchAvailabilityData(locationId, config.REACT_APP_API_URL, requiredDuration)
				.then((data) => setAvailabilityData(data))
				.catch((error) => console.error('Failed to fetch availability data:', error));
		}
	}, [selectedLocation, requiredDuration]);

	const handleLocationChange = (event) => {
		setSelectedTime(null);
		setSelectedDate(null);
		setClearSelectedTime(true);
		setClearSelectedDate(true);
		setSelectedLocation(event.target.value);
		onUpdateBookingDetails(selectedDate, selectedTime, event.target.value);
	};

	const handleDateTimeChange = (newDate, newTime) => {
		if (newDate && newTime && selectedLocation) {
			updateBookingDetails(bookingData, newDate, newTime, selectedLocation, setBookingData);
		}
	};

	const handleDateChange = (date) => {
		const newDate = date.value;
		setSelectedDate(newDate);
		setSelectedTime(null);
		setClearSelectedTime(true);
		if (selectedTime) {
			handleDateTimeChange(newDate, selectedTime);
		}
	};

	const handleTimeChange = (time) => {
		const newTime = time.value;
		setSelectedTime(newTime);
		if (selectedDate) {
			handleDateTimeChange(selectedDate, newTime);
		}
	};

	const handleRequiredDurationChange = (duration) => {
		setRequiredDuration(duration);
	};

	const findCommonLocations = () => {
		if (bookingData.length === 0 || services === null) {
			return [];
		}

		// Extracting service IDs from bookingData
		const bookingServiceIds = bookingData.map((booking) => booking.service.serviceId);

		// Extracting available locations for each booked service
		const allLocations = bookingServiceIds.map((serviceId) => {
			const service = services?.find((s) => s.service.service_id.toString() === serviceId);
			return service ? service.available_locations : [];
		});

		// Check if any of the available_locations arrays are undefined or not an array
		if (allLocations.some((locations) => !Array.isArray(locations))) {
			console.error('One or more services have invalid available_locations');
			return [];
		}

		// Finding common locations across all bookings
		const commonLocations = allLocations.reduce((acc, locations) =>
			acc.filter((location) => locations.includes(location))
		);
		return commonLocations;
	};
	const availableLocations = findCommonLocations();

	const findPhlebotomyLocations = () => {
		if (services === null) {
			return [];
		}
		const service = services.find((s) => s.service.service_id.toString() === '19');
		return service ? service.available_locations : [];
	};
	const phlebotomyLocations = findPhlebotomyLocations();

	const addServiceToBooking = () => {
		handleAddService(
			selectedService,
			selectedPatient,
			selectedLocation,
			selectedDate,
			selectedTime,
			setBookingData,
			user
		);
	};

	useEffect(() => {
		if (!!selectedLocation && !!selectedDate && !!selectedTime && bookingData.length > 0) {
			setContinueBtndisabled(false);
		} else {
			setContinueBtndisabled(true);
		}
	}, [selectedLocation, selectedDate, selectedTime, selectedPatient, bookingData]);

	return (
		<>
			<Header title="Portal" />
			<div className="container mt-25">
				<div className="row">
					<div className="col-md-5 col-lg-4 col-xl-3">
						<DashboardSidebar user={user} />
					</div>
					<div className="col-md-7 col-lg-8 col-xl-9">
						<Card
							contents={
								<div className="flex-column mx-auto" style={{ maxWidth: '550px', width: '100%' }}>
									{bookingRequestSent ? (
										<div
											className="booking-success-message"
											style={{
												minHeight: '400px',
												alignItems: 'center',
												display: 'flex',
											}}
										>
											<div>
												<h4>Group booking request received</h4>
												<p>
													Your group booking request has been received. We will reply within 24
													business hours.
												</p>
											</div>
										</div>
									) : (
										<>
											<div className="two-radio-selection group mt-4">
												<ThreeRadioSelection
													firstOption={'Blood tests'}
													firstOptionId={'bloodTests'}
													secondOption={'Phlebotomy only'}
													secondOptionId={'phlebotomyOnly'}
													thirdOption={'Imaging Scans'}
													thirdOptionId={'imagingScans'}
													name="groupBooking"
													required={true}
													onSelectionChange={(selectedOption) => {
														setSelectedBookingOption(selectedOption.value);
													}}
												/>
											</div>
											{selectedBookingOption === 'bloodTests' && (
												<>
													{/* Render Blood Tests components here */}
													<PatientSelect
														patients={existingPatients}
														selectedPatientValue={selectedPatientValue}
														onPatientChange={handlePatientChange}
														onOpenAddModal={openAddModal}
														onOpenEditModal={openEditModal}
													/>
													<NewPatientModal
														showAddModal={showAddModal}
														onClose={closeModal}
														newPatient={newPatient}
														onNewPatientChange={handleNewPatientChange}
														onSubmit={onSubmitNewPatient}
													/>
													<EditPatientModal
														showEditModal={showEditModal}
														onClose={closeModal}
														editedPatient={editedPatient}
														onEditChange={handleEditChange}
														onSubmit={onSubmitEditedPatient}
													/>
													<CustomiseMarkersModal
														showCustomiseMarkersModal={showCustomiseMarkersModal}
														onClose={closeModal}
														bookingItemId={customiseMarkersBookingItemId}
														customisedMarkersForm={customisedMarkersForm}
														onCustomiseChange={handleCustomiseChange}
														onSubmitCustomisedMarkers={onSubmitCustomisedMarkers}
													/>
													<ServiceSelect
														services={services}
														selectedServiceValue={selectedServiceValue}
														onServiceChange={handleServiceChange}
														onAddService={addServiceToBooking}
														bookingData={bookingData}
														setBookingData={setBookingData}
														onRequiredDurationChange={handleRequiredDurationChange}
														openCustomiseMarkersModal={openCustomiseMarkersModal}
														setSelectedLocation={setSelectedLocation}
													/>
													{selectedServiceValue && (
														<AppointmentCreate
															selectedLocation={selectedLocation}
															availableLocations={availableLocations}
															onLocationChange={handleLocationChange}
															availabilityData={availabilityData}
															selectedDate={selectedDate}
															onDateChange={handleDateChange}
															selectedTime={selectedTime}
															onTimeChange={handleTimeChange}
															onContinue={() => (window.location.href = '/cart')}
															continueBtndisabled={continueBtndisabled}
															requiredDuration={requiredDuration}
															user={user}
															bookingData={bookingData}
															setBookingData={setBookingData}
															handleDateTimeLocationSelection={handleDateTimeLocationSelection}
															setSelectedDate={setSelectedDate}
															setSelectedTime={setSelectedTime}
															clearSelectedTime={clearSelectedTime}
															setClearSelectedTime={setClearSelectedTime}
															clearSelectedDate={clearSelectedDate}
															setClearSelectedDate={setClearSelectedDate}
														/>
													)}
												</>
											)}

											{selectedBookingOption === 'phlebotomyOnly' && (
												<PhlebotomyBooking
													selectedLocation={selectedLocation}
													availableLocations={phlebotomyLocations}
													handleLocationChange={handleLocationChange}
													availabilityData={availabilityData}
													selectedDate={selectedDate}
													onDateChange={handleDateChange}
													selectedTime={selectedTime}
													onTimeChange={handleTimeChange}
													onContinue={() => (window.location.href = '/cart')}
													continueBtndisabled={continueBtndisabled}
													requiredDuration={requiredDuration}
													selectedPatient={selectedPatient}
													selectedService={selectedService}
													user={user}
													bookingData={bookingData}
													setBookingData={setBookingData}
													handleDateTimeLocationSelection={handleDateTimeLocationSelection}
													setSelectedDate={setSelectedDate}
													setSelectedTime={setSelectedTime}
													onBookingRequestSent={() => setBookingRequestSent(true)}
												/>
											)}

											{selectedBookingOption === 'imagingScans' && (
												<div className="imaging-scans-section mt-4">
													<p>
														Please complete the{' '}
														<a
															href="https://forms.office.com/e/gZ5HVLndXf"
															style={{ color: 'blue', textDecoration: 'underline' }}
															target="_blank"
															rel="noopener noreferrer"
														>
															contact form
														</a>{' '}
														with more details so we can better assist you. If you have any
														questions, please email{' '}
														<a
															href="mailto:customers@sirkkaltd.com"
															style={{ color: 'blue', textDecoration: 'underline' }}
														>
															customers@sirkkaltd.com
														</a>{' '}
														or call +44 (0)3300 882538 (9am to 5:30pm Mon-Fri).
													</p>
												</div>
											)}
										</>
									)}
								</div>
							}
							fullWidth
							large
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Appointments;

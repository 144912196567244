import React, { useState, useEffect } from 'react';
import { Button } from '@sirkka-health/booking-system-ui';

const PhlebotomyRequestDetails = ({
	showPRDModal,
	onClose,
	phlebotomyRequestDetails,
	onPRDChange,
}) => {
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [unfilledFields, setUnfilledFields] = useState([]);

	useEffect(() => {
		const unfilled = checkRequiredFieldsAndReturnUnfilled();
		setUnfilledFields(unfilled);
		setButtonDisabled(unfilled.length > 0);
	}, [phlebotomyRequestDetails]);

	const checkRequiredFieldsAndReturnUnfilled = () => {
		let requiredFields = [
			'numberOfCustomer',
			'testRequired',
			'testCodeOrVials',
			'availableParking',
			'parkingDescription',
			'specialRequirements',
			'specialNeedsPatients',
			'customerInfoProvisionDate',
			'testingSiteContact',
			'customerConsent',
		];

		// Fields conditionally required if the number of customers is 4 or more
		const conditionalFields =
			parseInt(phlebotomyRequestDetails.numberOfCustomer) >= 4
				? [
						'phlebotomyRequired',
						'facilities',
						'quantityOfFacilities',
						'binArrangement',
						'sampleCollection',
						'TDLDetails',
					]
				: [];

		requiredFields = [...requiredFields, ...conditionalFields];

		return requiredFields.filter((field) => !phlebotomyRequestDetails[field]);
	};

	if (!showPRDModal) return null;

	if (showPRDModal)
		return (
			<div
				className="modal"
				style={{
					display: 'block',
					backgroundColor: 'rgba(0, 0, 0, 0.5)',
					cursor: 'pointer',
				}}
				onClick={onClose}
			>
				<div
					className="modal-content"
					style={{
						backgroundColor: 'white',
						padding: '30px',
						margin: '20px auto',
						width: '100%',
						maxWidth: '600px',
					}}
					onClick={(e) => e.stopPropagation()}
				>
					<span className="close" onClick={onClose}>
						&times;
					</span>
					<h2>Phlebotomy Request Details</h2>
					<form>
						<div className="mt-4">
							<label>
								How many customers will be attending <span>*</span>
							</label>
							<input
								required
								type="number"
								name="numberOfCustomer"
								value={phlebotomyRequestDetails.numberOfCustomer}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Which tests are required? <span>*</span>
							</label>
							<input
								required
								type="text"
								name="testRequired"
								value={phlebotomyRequestDetails.testRequired}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Please provide the TDL test code or types of vials per person <span>*</span>
							</label>
							<input
								required
								type="text"
								name="testCodeOrVials"
								value={phlebotomyRequestDetails.testCodeOrVials}
								onChange={onPRDChange}
							/>
						</div>
						{parseInt(phlebotomyRequestDetails.numberOfCustomer) >= 4 && (
							<div>
								<label>
									How many Phlebotomists are required? <span>*</span>
								</label>
								<input
									required
									type="number"
									name="phlebotomyRequired"
									value={phlebotomyRequestDetails.phlebotomyRequired}
									onChange={onPRDChange}
								/>
							</div>
						)}

						<div>
							<label>
								Is there parking available on site? <span>*</span>
							</label>
							<input
								required
								type="text"
								name="availableParking"
								value={phlebotomyRequestDetails.availableParking}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Please provide details of the parking <span>*</span>
							</label>
							<textarea
								required
								name="parkingDescription"
								value={phlebotomyRequestDetails.parkingDescription}
								onChange={onPRDChange}
							/>
						</div>
						{parseInt(phlebotomyRequestDetails.numberOfCustomer) >= 4 && (
							<>
								<div>
									<label>
										What facilities does the location have? <span>*</span>
									</label>
									<input
										required
										type="text"
										name="facilities"
										value={phlebotomyRequestDetails.facilities}
										onChange={onPRDChange}
									/>
								</div>

								<div>
									<label>
										Please provide the quantity of each facility selected <span>*</span>
									</label>
									<input
										required
										type="text"
										name="quantityOfFacilities"
										value={phlebotomyRequestDetails.quantityOfFacilities}
										onChange={onPRDChange}
									/>
								</div>
							</>
						)}

						<div>
							<label>
								Please state any special requirements <span>*</span>
							</label>
							<textarea
								required
								name="specialRequirements"
								value={phlebotomyRequestDetails.specialRequirements}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Are there any special needs patients? <span>*</span>
							</label>
							<input
								required
								type="text"
								name="specialNeedsPatients"
								value={phlebotomyRequestDetails.specialNeedsPatients}
								onChange={onPRDChange}
							/>
						</div>

						{parseInt(phlebotomyRequestDetails.numberOfCustomer) >= 4 && (
							<>
								<div>
									<label>
										Who will arrange for the collection of sharps bin? <span>*</span>
									</label>
									<input
										required
										type="text"
										name="binArrangement"
										value={phlebotomyRequestDetails.binArrangement}
										onChange={onPRDChange}
									/>
								</div>

								<div>
									<label>
										Who will arrange couriers for the samples' collection and delivery to TDL?{' '}
										<span>*</span>
									</label>
									<input
										required
										type="text"
										name="sampleCollection"
										value={phlebotomyRequestDetails.sampleCollection}
										onChange={onPRDChange}
									/>
								</div>
							</>
						)}
						<div>
							<label>
								If TDL to be selected, please specify details: <span>*</span>
							</label>
							<textarea
								required
								name="TDLDetails"
								value={phlebotomyRequestDetails.TDLDetails}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								When will the customers' details be sent to Sirkka? <span>*</span>
							</label>
							<input
								required
								type="date"
								name="customerInfoProvisionDate"
								value={phlebotomyRequestDetails.customerInfoProvisionDate}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Testing site contact name and phone number <span>*</span>
							</label>
							<input
								required
								type="text"
								name="testingSiteContact"
								value={phlebotomyRequestDetails.testingSiteContact}
								onChange={onPRDChange}
							/>
						</div>

						<div>
							<label>
								Customer consent forms to be obtained by <span>*</span>
							</label>
							<input
								required
								type="text"
								name="customerConsent"
								value={phlebotomyRequestDetails.customerConsent}
								onChange={onPRDChange}
							/>
						</div>

						<div className="mb-4">
							<label>Other comments (if any)</label>
							<textarea
								name="requestComments"
								value={phlebotomyRequestDetails.requestComments}
								onChange={onPRDChange}
							/>
						</div>

						{unfilledFields.length > 0 && unfilledFields.length < 5 && (
							<div style={{ color: 'red', marginBottom: '20px' }}>
								Please fill in all required fields. Missing: {unfilledFields.join(', ')}
							</div>
						)}

						<Button
							onClick={onClose}
							label={'Complete Phlebotomy Request Details'}
							fullWidth
							large
							disabled={buttonDisabled}
						></Button>
					</form>
				</div>
			</div>
		);
};

export default PhlebotomyRequestDetails;

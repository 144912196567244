import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Postcoder from 'react-address-lookup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import config from '../../config';
import useAuth from '../../hooks/useAuth';
import LoginButton from '../../components/LoginButton';
import showAlert from '../../helpers/alerts';
import { createAccount } from '../../services/data';
import checkData from '../../validations/registrationForm';
import './AddressLookup.css';
import AuthenticationFormWrapper from './AuthenticationFormWrapper';
import { formatEmail } from '../../validations/email';
import './RegistrationForm.scss';

const RegistrationForm = () => {
	// Populated when user is invited via email to join a group (Household)
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const groupId = searchParams.get('id');
	const registrationType = searchParams.get('type');

	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();

	if (isAuthenticated) {
		navigate('/customer-dashboard');
	}

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		shouldFocusError: true,
	});

	const [registrationStatus, setRegistrationStatus] = useState(false);
	const [selectedTab, setSelectedTab] = useState(() => {
		if (registrationType === 'business') {
			return 'business';
		} else if (registrationType === 'personal') {
			return 'personal';
		}
		// Default to none if registrationType is not 'business' or 'personal'
		return 'personal';
	});
	const [passwordShown] = useState(false);

	const [visible, setIsVisible] = useState(false);
	const [address, setAddress] = useState({
		address_line_1: '',
		address_line_2: '',
		city: '',
		postcode: '',
		country: '',
		address_type: 'home',
	});

	const useAddress = ({ addressline1, addressline2, posttown, postcode, country }) => {
		setAddress({
			address_line_1: addressline1,
			address_line_2: addressline2,
			city: posttown,
			postcode: postcode,
			country: country || 'United Kingdom',
			address_type: 'home',
		});

		setIsVisible(true);
	};

	const handleDateChange = (date) => {
		const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
		setValue('dob', formattedDate);
	};

	const onSubmit = async (data) => {
		if (groupId) {
			data.groupId = groupId;
		}
		if (address) {
			data.address = address;
		}

		if (selectedTab === 'business') {
			data.role = 'business';
		}

		const valid = checkData(data, selectedTab);
		let errorMessage = valid;

		if (valid === 'valid') {
			const response = await createAccount(data);
			if (response.status === 'success') {
				return setRegistrationStatus(true);
			}
			errorMessage = response.message;
		}

		if (errorMessage) showAlert(errorMessage);
	};

	const onError = (error, e) => {
		console.log(error, e);
	};

	if (registrationStatus) {
		return (
			<AuthenticationFormWrapper>
				<h3 className="text-center mb-30">Account successfully created!</h3>
				<p className="text-center mb-30">
					<b>
						Please note that you will need to login, select an appointment time to complete your
						booking. This is not a booking confirmation.
					</b>
				</p>
				<div className="or-divide"></div>
				<LoginButton authType={registrationType} />
			</AuthenticationFormWrapper>
		);
	}

	const handleTabClick = (event, value) => {
		setSelectedTab(value);
	};

	return (
		<AuthenticationFormWrapper>
			<h3 className="text-center mb-30">Register</h3>
			<Tabs value={selectedTab} onChange={handleTabClick} centered>
				<Tab key="business" value="business" label="For Business" />
				<Tab key="personal" value="personal" label="For Personal" />
			</Tabs>
			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<p className="mt-4 mb-4">
					<i>
						Please use your official ID's full name including middle name(s). You will be asked via
						an email for an ID verification so you can access your account.
					</i>
				</p>
				<p className="mt-4 mb-4">
					<b>
						If you are registering/booking on behalf of someone else, please ensure that you use
						their details.
					</b>
				</p>
				<label htmlFor="nameTitle" className="mb-2">
					Title <span>*</span>
				</label>
				<select {...register('nameTitle', { required: true })} required>
					<option value="" disabled selected>
						Please select one
					</option>
					<option value="Mr">Mr</option>
					<option value="Miss">Miss</option>
					<option value="Mrs">Mrs</option>
					<option value="Other">Other</option>
				</select>
				<label htmlFor="firstName" className="mb-2">
					{' '}
					First name <span>*</span>
				</label>
				<input
					{...register('firstName', {
						required: '',
					})}
					required
					id="firstName"
					type="text"
					placeholder="Enter first name"
				/>
				<ErrorMessage errors={errors} name="firstName" />
				<label htmlFor="lastName" className="mb-2">
					Last name <span>*</span>
				</label>
				<input
					{...register('lastName', { required: true })}
					required
					id="lastName"
					type="text"
					placeholder="Enter last name"
				/>
				<ErrorMessage errors={errors} name="lastName" />
				{selectedTab === 'personal' && (
					<>
						<label htmlFor="gender" className="mb-2">
							Gender <span>*</span>
						</label>
						<select {...register('gender')}>
							<option value="Male">Male</option>
							<option value="Female">Female</option>
							<option value="Other">Other</option>
						</select>
						<ErrorMessage errors={errors} name="gender" />
						<label htmlFor="dob" className="mb-2">
							Date of birth <span>*</span>
						</label>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								{...register('dob')}
								id="dob"
								required
								className="mb-2 col-12"
								format="DD/MM/YYYY"
								onChange={handleDateChange}
								sx={{
									'.MuiOutlinedInput-notchedOutline': { border: 'none' },
									'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
										border: 0,
									},
									'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
										border: 0,
									},
								}}
								desktopModeMediaQuery="@media (min-width: 0px)"
							/>
						</LocalizationProvider>
						<ErrorMessage errors={errors} name="dob" />
					</>
				)}
				<label htmlFor="phoneNumber" className="mb-2">
					Phone number <span>*</span>
				</label>
				<input
					{...register('phoneNumber')}
					required
					id="phoneNumber"
					name="phoneNumber"
					type="tel"
				/>
				<ErrorMessage errors={errors} name="phoneNumber" />
				<label htmlFor="address" className="mb-2">
					Address <span>*</span>
				</label>
				<Postcoder
					apiKey={config.REACT_APP_POSTCODER}
					addressSelectedCallback={useAddress}
					labelText=""
					placeholder={'Search your postcode'}
				/>
				{visible && (
					<>
						<label htmlFor="address_line_1" className="mb-2">
							Address line 1 <span>*</span>
						</label>
						<input
							{...register('address_line_1', {
								required: true,
							})}
							required
							id="address_line_1"
							type="text"
							placeholder="Address Line 1"
							defaultValue={address.address_line_1}
						/>
						<ErrorMessage errors={errors} name="address_line_1" />

						<label htmlFor="address_line_2" className="mb-2">
							Address line 2
						</label>
						<input
							{...register('address_line_2')}
							id="address_line_2"
							type="text"
							placeholder="Address Line 2"
							defaultValue={address.address_line_2}
						/>
						<ErrorMessage errors={errors} name="address_line_2" />

						<label htmlFor="city" className="mb-2">
							City <span>*</span>
						</label>
						<input
							{...register('city', { required: true })}
							required
							id="city"
							type="text"
							placeholder="City"
							defaultValue={address.city}
						/>
						<ErrorMessage errors={errors} name="city" />

						<label htmlFor="country" className="mb-2">
							Country <span>*</span>
						</label>
						<input
							{...register('country', { required: true })}
							required
							id="country"
							type="text"
							placeholder="Country"
							defaultValue={address.country}
						/>
						<ErrorMessage errors={errors} name="country" />

						<label htmlFor="postcode" className="mb-2">
							Postcode <span>*</span>
						</label>
						<input
							{...register('postcode', { required: true })}
							required
							id="postcode"
							type="text"
							placeholder="Postcode"
							defaultValue={address.postcode}
						/>
						<ErrorMessage errors={errors} name="postcode" />
					</>
				)}
				<ErrorMessage errors={errors} name="address" />
				<label htmlFor="company" className="mb-2">
					Company <span>*</span>
				</label>
				<input {...register('company')} id="company" name="company" type="text" />
				<ErrorMessage errors={errors} name="company" />
				{selectedTab === 'business' && (
					<>
						<label htmlFor="occupation" className="mb-2">
							Position in the company
						</label>
						<input {...register('occupation')} id="occupation" name="occupation" type="text" />
						<label htmlFor="company_reg" className="mb-2">
							Company Registration Number <span>*</span>
						</label>
						<input
							{...register('companyRegistration')}
							id="company_reg"
							name="companyRegistration"
							type="text"
						/>
						<ErrorMessage errors={errors} name="companyRegistration" />
					</>
				)}
				{selectedTab === 'personal' && (
					<>
						<label htmlFor="occupation" className="mb-2">
							Occupation
						</label>
						<input {...register('occupation')} id="occupation" name="occupation" type="text" />
						<ErrorMessage errors={errors} name="occupation" />
					</>
				)}
				<label htmlFor="email-id" className="mb-2">
					Email address <span>*</span>
				</label>
				<input
					{...register('email', {
						setValueAs: (v) => formatEmail(v),
					})}
					required
					id="email-id"
					name="email"
					type="text"
					placeholder="Enter Username or Email address"
				/>
				<ErrorMessage errors={errors} name="email" />
				<label htmlFor="pass" className="mb-2">
					Password <span>*</span>
				</label>
				<input
					{...register('password')}
					required
					id="pass"
					name="password"
					type={passwordShown ? 'text' : 'password'}
					placeholder="Enter password"
				/>
				<ErrorMessage errors={errors} name="password" />
				<label htmlFor="pass" className="mb-2">
					Confirm password <span>*</span>
				</label>
				<input
					{...register('confirmPassword')}
					required
					id="confirm-pass"
					name="confirmPassword"
					type={passwordShown ? 'text' : 'password'}
					placeholder="Confirm password"
				/>
				<ErrorMessage errors={errors} name="confirmPassword" />
				{selectedTab === 'personal' && (
					<>
						<input
							{...register('emailConsent')}
							type="checkbox"
							id="emailConsent"
							name="emailConsent"
							className="hidden"
						></input>
						<label htmlFor="emailConsent" className="checkbox">
							Keep me updated by email on Sirkka Health&apos;s offers and updates
						</label>
					</>
				)}
				{/* <button className="primary_btn theme-btn-2 w-20" onClick={togglePassword}>Show Password</button> */}
				<div className="mt-10"></div>
				<button type="submit" className="primary_btn w-100">
					Register
				</button>
				<div className="or-divide">
					<span>or</span>
				</div>
				<LoginButton />
			</form>
		</AuthenticationFormWrapper>
	);
};

export default RegistrationForm;

import React, { useState } from 'react';
import './Card.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import categoryJson from './treatmentAbroadCategory.json';
import ReadMore from '../ReadMore';

export default function InternationalTreatmentCard({
	type,
	entityItem,
	onOpenModal,
	onHover,
	onLeave,
}) {
	const [isHovered, setIsHovered] = useState(false);
	const [showAllServices, setShowAllServices] = useState(false);

	const bookingEntity = entityItem.entity_with_services.booking_entity;
	const availableServices = entityItem.entity_with_services.available_services;

	const handleMouseEnter = () => {
		onHover(bookingEntity.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};

	const handleOpenModal = (service, bookingEntity) => {
		onOpenModal(service, bookingEntity);
	};

	const renderServiceButton = (service) => {
		if (service.bookable && service.service_code) {
			return (
				<Button label="Book" onClick={() => handleOpenModal(service, bookingEntity)} fullWidth />
			);
		} else if (service.redirect_url) {
			return <Button label="Details" onClick={() => window.open(service.redirect_url)} fullWidth />;
		} else {
			return service.service_price ? (
				<p className="mb-0">
					from{' '}
					{new Intl.NumberFormat('en-GB', {
						style: 'currency',
						currency: 'GBP',
					}).format(service.service_price / 100)}
				</p>
			) : null;
		}
	};

	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	const toggleServices = () => setShowAllServices(!showAllServices);

	// Step 1: Filter categories that match the current booking entity
	const matchingCategories = categoryJson.filter((categoryData) => {
		const bookingEntityName = bookingEntity.display_name.toLowerCase();
		return bookingEntityName.includes(categoryData.for.toLowerCase());
	});

	// Step 2: Categorize services based on tags and the matching categories
	const categorizedServices = [];
	const uncategorizedServices = [];

	matchingCategories.forEach((categoryData) => {
		const { category, categoryDescription, websiteUrl, tags: categoryTags } = categoryData;

		const matchingServices = availableServices.filter((service) => {
			const serviceTags = service.service.tags
				.toLowerCase()
				.split(',')
				.map((tag) => tag.trim());

			// Check if the service matches any category tag
			return categoryTags.some((categoryTag) =>
				serviceTags.some((serviceTag) => serviceTag.includes(categoryTag.toLowerCase()))
			);
		});

		if (matchingServices.length > 0) {
			categorizedServices.push({
				category,
				categoryDescription,
				websiteUrl,
				services: matchingServices,
			});
		}
	});

	// Step 3: Find services that don't match any category
	uncategorizedServices.push(
		...availableServices.filter((service) => {
			const serviceTags = service.service.tags
				.toLowerCase()
				.split(',')
				.map((tag) => tag.trim());

			// Return services that don't match any category in matchingCategories
			return matchingCategories.every((categoryData) =>
				categoryData.tags.every(
					(categoryTag) =>
						!serviceTags.some((serviceTag) => serviceTag.includes(categoryTag.toLowerCase()))
				)
			);
		})
	);

	return (
		<div
			className="px-3 py-3 mb-3"
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="d-flex flex-column flex-md-row card-content-container">
				<div className="col-12 col-sm-12 col-md-5 col-xl-4">
					<div className="image-container">
						<img src={bookingEntity.image_url} alt={bookingEntity.display_name} />
					</div>
				</div>
				<div className="col-12 col-sm-12 col-md-7 col-xl-8 ps-sm-3 pt-4 pt-md-0">
					<div className="d-flex align-items-center mb-15">
						<div className="col-9">
							<h4 className="mb-0">{bookingEntity.display_name}</h4>
						</div>
						<div className="col-3">
							{bookingEntity.website_url && (
								<a href={bookingEntity.website_url} target="_blank" rel="noopener noreferrer">
									<Button
										label={bookingEntity.website_url.startsWith('mailto') ? 'Email' : 'Website'}
										fullWidth
										variant="border"
									/>
								</a>
							)}
						</div>
					</div>
					<ReadMore content={bookingEntity.description} />
				</div>
			</div>

			{/* Step 4: Render categorized services */}
			{categorizedServices.map((category, index) => (
				<div key={index} className="category-section mt-4">
					<h4 style={{ color: 'navy' }}>{category.category}</h4>
					{category.categoryDescription && <p>{category.categoryDescription}</p>}
					{category.services
						.slice(0, showAllServices ? category.services.length : 3)
						.map((serviceItem, index) => (
							<div className="card-service d-flex px-3 mt-3" key={index}>
								<div className="col-9 col-lg-10">
									<h6 style={{ fontSize: '16px' }}>{serviceItem.service.service_name}</h6>
								</div>
								<div className="col-3 col-lg-2 justify-content-end text-end align-self-center">
									{renderServiceButton(serviceItem.service)}
								</div>
							</div>
						))}
				</div>
			))}

			{/* Step 5: Render uncategorized services */}
			{uncategorizedServices.length > 0 && (
				<div className="category-section mt-4">
					<h4 style={{ color: 'navy' }}>Other Services</h4>
					{uncategorizedServices
						.slice(0, showAllServices ? uncategorizedServices.length : 3)
						.map((serviceItem, index) => (
							<div className="card-service d-flex px-3 mt-3" key={index}>
								<div className="col-9 col-lg-10">
									<h6 style={{ fontSize: '16px' }}>{serviceItem.service.service_name}</h6>
								</div>
								<div className="col-3 col-lg-2 justify-content-end text-end align-self-center">
									{renderServiceButton(serviceItem.service)}
								</div>
							</div>
						))}
				</div>
			)}

			{/* Show "View all" or "View less" button if more than 3 services available */}
			{availableServices.length > 3 && (
				<button
					className={`view-more-button pb-2 ${showAllServices ? 'expanded' : ''}`}
					onClick={toggleServices}
				>
					{showAllServices ? 'View Less' : 'View all'}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<polyline points="6 9 12 15 18 9"></polyline>
					</svg>
				</button>
			)}
		</div>
	);
}

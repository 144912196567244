import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import { getServices, getSpecialists } from '../../pages/HealthTestsAndSpecialists/helpers/getData';

function Searchbar() {
	const {
		services,
		setServices,
		specialists,
		setSpecialists,
		servicesAndSpecialists,
		setServicesAndSpecialists,
		selectedLocationCategory,
	} = useContext(BookingContext);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedItem, setSelectedItem] = useState(null);
	const [filteredItems, setFilteredItems] = useState([]);
	const [isFocused, setIsFocused] = useState(false);
	const navigate = useNavigate();
	let blurTimeout = useRef(null);

	useEffect(() => {
		if (!services || services.length === 0) {
			getServices(setServices).catch((error) => console.error(error));
		}
		if (!specialists || specialists.length === 0) {
			getSpecialists(setSpecialists).catch((error) => console.error(error));
		}
	}, [searchTerm, services, setServices, specialists, setSpecialists]);

	useEffect(() => {
		if (services && specialists) {
			const combined = [];
			let serviceIndex = 0;
			let specialistIndex = 0;

			while (serviceIndex < services.length || specialistIndex < specialists.length) {
				if (serviceIndex < services.length) {
					combined.push(services[serviceIndex++]);
				}
				if (serviceIndex < services.length) {
					combined.push(services[serviceIndex++]);
				}
				if (specialistIndex < specialists.length) {
					combined.push(specialists[specialistIndex++]);
				}
			}

			setServicesAndSpecialists(combined);
		}
	}, [services, specialists, setServicesAndSpecialists]);

	useEffect(() => {
		if (searchTerm) {
			// Filter services
			const serviceResults = servicesAndSpecialists.filter(
				(item) =>
					item.service && item.service.service_name.toLowerCase().includes(searchTerm.toLowerCase())
			);

			// Filter specialists
			const specialistResults = servicesAndSpecialists.filter((item) => {
				if (item.metadata) {
					return (
						item.metadata.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
						item.metadata.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
						(item.metadata.tags &&
							JSON.parse(item.metadata.tags.replace(/'/g, '"')).some((tag) =>
								tag.toLowerCase().includes(searchTerm.toLowerCase())
							))
					);
				}
				return false;
			});

			const combinedResults = [...serviceResults, ...specialistResults];

			setFilteredItems(combinedResults);
		}
	}, [searchTerm, servicesAndSpecialists]);

	const handleBlur = () => {
		blurTimeout.current = setTimeout(() => {
			setIsFocused(false);
		}, 100);
	};

	const handleFocus = () => {
		clearTimeout(blurTimeout.current);
		setIsFocused(true);
	};

	const handleItemMouseDown = (item) => {
		setSelectedItem(item);
		setIsFocused(false);
		if (item.service) {
			navigate(`/booking?serviceCode=${item.service.service_code}`);
		} else if (item.metadata) {
			navigate(`/booking?specialistCode=${item.user_id}`);
		}
	};

	return (
		<div style={{ width: '100%', position: 'relative', zIndex: '10' }}>
			<input
				type="text"
				placeholder="Search tests and services..."
				value={searchTerm}
				className="search"
				onChange={(e) => setSearchTerm(e.target.value)}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			{isFocused && searchTerm && (
				<ul className="results">
					{filteredItems.length ? (
						filteredItems.map((item) => (
							<li
								key={item.service ? item.service.service_id : item.user_id}
								onMouseDown={() => handleItemMouseDown(item)}
							>
								{item.service
									? item.service.service_name
									: `${item.metadata.personal_title ? item.metadata.personal_title + ' ' : ''}${
											item.metadata.first_name ? item.metadata.first_name + ' ' : ''
										}${item.metadata.last_name || ''}`}
								{item.metadata && item.metadata.tags && (
									<div className="tags">
										{JSON.parse(item.metadata.tags.replace(/'/g, '"'))
											.filter((tag) => tag !== 'Virtual' && tag !== 'Face-to-face')
											.map((tag, index) => (
												<span key={index}>{tag}</span>
											))}
									</div>
								)}
							</li>
						))
					) : (
						<li>No results found</li>
					)}
				</ul>
			)}
		</div>
	);
}

export default Searchbar;

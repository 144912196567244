import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { Loader } from '@/components';
import { getRequest } from '@/services/api';
import { getJsonData } from '@/services/data';
import { BookingContext } from '@sirkka-health/booking-system-ui';
import config from '@/config';
import fetchData from '../../helpers/dataFetch';
import { HeroProps, Location, ServiceData, Entity, PageData } from '@/types';
import addItToCart from '../../helpers/addToCart';
import formatTitle from '../../helpers/formatTitle';
import { Header, Footer, Modal, Hero } from '@/components';
import CardList from '../../components/CardList';
import CardListMap from '../../components/CardListMap';
import CardListFilter from '../../components/CardListFilter';
import EventModalContent from '../../components/ModalContents/EventModalContent';
import Faqs from '../FAQS';
import formatHeading from '../../helpers/format-heading';
import '../../components/Listings/listings.scss';

const EventPage = () => {
	const url = '/events';
	const faqData = getJsonData('faqData');

	const [filteredEntityData, setFilteredEntityData] = useState<Entity[]>([]);
	const [events, setEvents] = useState<any[]>([]);
	const [hoveredCardId, setHoveredCardId] = useState<null | string>(null);
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedEntityItem, setSelectedEntityItem] = useState<Entity | null>(null);
	const [selectedServiceItem, setSelectedServiceItem] = useState<Entity | null>(null);
	const [totalEntity, setTotalEntity] = useState(0);
	const [totalDisplayed, setTotalDisplayed] = useState(0);
	const [loading, setLoading] = useState(false);
	const [serviceData, setServiceData] = useState<ServiceData | null>(null);
	const [currentPageData, setCurrentPageData] = useState<PageData | null>(null);
	const [accessCode, setAccessCode] = useState<string | null>(null);

	const navigate = useNavigate();
	const { bookingData, setBookingData } = useContext(BookingContext) as any;
	const { isLoaded } = useLoadScript({ googleMapsApiKey: config.REACT_APP_GOOGLE_MAPS_API_KEY });
	const isMapLoaded = isLoaded;

	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			try {
				const processResponse = ({ events, total }: { events: any[]; total: number }) => {
					if (events) {
						const today = new Date();
						const now = new Date(); // Current date and time

						const sortedEvents = events.sort((a, b) => {
							const dateAStart = new Date(`${a.event_date}T00:00:00Z`);
							const dateAEnd = new Date(`${a.event_end_date || a.event_date}T23:59:59Z`); // Assuming event_end_date exists or defaults to event_date
							const dateBStart = new Date(`${b.event_date}T00:00:00Z`);
							const dateBEnd = new Date(`${b.event_end_date || b.event_date}T23:59:59Z`); // Assuming event_end_date exists or defaults to event_date

							// Check if event A is ongoing
							const isAUpcomingOrOngoing =
								dateAStart >= today || (dateAStart <= now && dateAEnd >= now);
							// Check if event B is ongoing
							const isBUpcomingOrOngoing =
								dateBStart >= today || (dateBStart <= now && dateBEnd >= now);

							// Case 1: Both events are upcoming or ongoing
							if (isAUpcomingOrOngoing && isBUpcomingOrOngoing) {
								return dateAStart.getTime() - dateBStart.getTime(); // Upcoming events sorted by soonest first
							}

							// Case 2: Both events are in the past
							if (!isAUpcomingOrOngoing && !isBUpcomingOrOngoing) {
								return dateAStart.getTime() - dateBStart.getTime(); // Past events sorted by oldest first
							}

							// Case 3: One event is upcoming or ongoing, the other is in the past
							if (isAUpcomingOrOngoing) {
								return -1; // Event A is upcoming or ongoing, it should come before past events
							}

							return 1; // Event B is upcoming or ongoing, it should come before past events
						});

						setEvents(sortedEvents);
					}
					if (total) setTotalEntity(total);
				};
				await fetchData(url, processResponse);
			} catch (error) {
				console.error('Error loading data:', error);
			} finally {
				setLoading(false);
			}
		};
		loadData();
	}, [url]);

	useEffect(() => {
		const pageData = getJsonData('pageData') as PageData[];
		const matchedPageData = pageData.find(
			(item: any) => item.type.replace(/\s+/g, '-').toLowerCase() === 'group-testing'
		);

		if (matchedPageData) {
			setCurrentPageData(matchedPageData);
		} else {
			console.log('Didnt find a match for group-testing data');
		}
	}, []);

	const handleOpenModal = (serviceItem: any, entityItem: any, accessCode: string | null) => {
		setSelectedEntityItem(entityItem);
		setSelectedServiceItem(serviceItem);
		if (accessCode) {
			setAccessCode(accessCode);
		}
		setModalOpen(true);
	};

	const handleCardHover = (cardId: string | null) => setHoveredCardId(cardId);
	const handleCardLeave = () => setHoveredCardId(null);
	const handleTotalDisplayedCards = (count: number) => setTotalDisplayed(count);

	useEffect(() => {
		const fetchServiceData = async (serviceId: string) => {
			try {
				const service = await getRequest(`/bookings/bookingEntities/service/${serviceId}`);
				const entity: Entity = service.entities[0];
				setServiceData({
					service: {
						...entity,
						service_bookable: entity.bookable,
					},
					category: { service_category_id: entity.location_category_id },
					content: { ...entity, service_icon: entity.service_icon },
					location: { ...entity, location_category: entity.city || 'Virtual' },
				});
			} catch (error) {
				console.error('Error fetching service data:', error);
			}
		};
		if (selectedServiceItem?.service_id) fetchServiceData(selectedServiceItem.service_id);
	}, [selectedServiceItem]);

	const title = formatTitle('group-testing');

	const cardListProps = {
		type: 'group-testing',
		entityData: filteredEntityData,
		events: events || [],
		onTotalDisplayedCards: handleTotalDisplayedCards,
		onOpenModal: handleOpenModal,
		onCardHover: handleCardHover,
		onCardLeave: handleCardLeave,
	};

	const addToCart = (location: Location, matchedService: any, bookingEntityId: string) => {
		addItToCart(
			location,
			matchedService,
			bookingEntityId,
			bookingData,
			setBookingData,
			serviceData,
			navigate,
			selectedServiceItem
		);
	};

	const modalContent = {
		type: 'group-testing',
		selectedServiceItem,
		selectedEntityItem,
		serviceData,
		addToCart,
		accessCode,
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Header title={title} fullTitle={title} />
			<Hero
				type="group-testing"
				entityData={[]}
				events={events}
				setEvents={setEvents}
				setFilteredEntityData={setFilteredEntityData}
				isListingPage={true}
				onOpenModal={handleOpenModal}
				onCardHover={handleCardHover}
				onCardLeave={handleCardLeave}
				onTotalDisplayedCards={handleTotalDisplayedCards}
			/>
			<div className="container">
				<div className="row">
					<CardListFilter totalDisplayed={totalDisplayed} entityData={filteredEntityData} />
					{cardListProps.events.length === 0 ? (
						<div className="col-xl-7 col-lg-7 col-md-12">
							<div className="mb-4">
								<p>There are no events at the moment. Coming soon.</p>
							</div>
						</div>
					) : (
						<CardList
							type={'group-testing'}
							events={events}
							entityData={filteredEntityData}
							onTotalDisplayedCards={handleTotalDisplayedCards}
							onOpenModal={handleOpenModal}
							onCardHover={handleCardHover}
							onCardLeave={handleCardLeave}
						/>
					)}
					{isMapLoaded && (
						<CardListMap
							type={'group-testing'}
							events={events}
							entityData={filteredEntityData}
							hoveredCard={hoveredCardId}
						/>
					)}
				</div>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
				content={<EventModalContent {...modalContent} />}
			/>
			{faqData && (
				<div style={{ marginTop: '50px' }}>
					{Object.keys(faqData).map((faqCategory, index) => (
						<Faqs
							key={index}
							heading={formatHeading(faqCategory, 'word')}
							faqs={faqData[faqCategory]}
						/>
					))}
				</div>
			)}
			<Footer />
		</>
	);
};

export default EventPage;

import React, { useState, useMemo, useEffect, ChangeEvent, FormEvent } from 'react';
import './Hero.scss';
import { Button } from '@sirkka-health/booking-system-ui';
import Select, { MultiValue, SingleValue, StylesConfig, GroupBase } from 'react-select';
import makeAnimated from 'react-select/animated';
import config from '../../config';
import Geocode from 'react-geocode';
import { useLocation, useNavigate } from 'react-router-dom';
import { HeroProps, TagOption, Coords } from '../../types';
import debounce from '../../helpers/debounce';
import pageData from '@/data/pageData.json';
import { getRequest } from '../../services/api';

const Hero = ({
	type,
	entityData,
	events,
	setEvents,
	setFilteredEntityData,
	isListingPage = false,
}: HeroProps) => {
	const [chosenServices, setChosenServices] = useState<MultiValue<TagOption>>([]);
	const [selectedLocation, setSelectedLocation] = useState('');
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [selectedProvider, setSelectedProvider] = useState<SingleValue<TagOption>>(null);
	const [selectedDateSort, setSelectedDateSort] = useState<SingleValue<TagOption>>(null);
	const [formOrder, setFormOrder] = useState(
		type === 'group-testing' ? 'dateLocation' : 'serviceLocation'
	);
	const animatedComponents = makeAnimated();
	const location = useLocation();
	const navigate = useNavigate();

	const tagButtons = [
		'Diagnostic Tests',
		'Physiotherapy',
		'Osteopathy',
		'Nutrition',
		'Vaccinations',
		'Occupational Health',
		'Orthopaedic',
		'Bariatric',
		'Plastic Surgery',
		'Fertility',
		'Counsellor',
		'Psychologist',
		'Psychotherapist',
		'Wellness Treatment',
	];

	const currentPageData = pageData.find((page) => page.type === type) || null;

	const specialistTagButtons = [
		'Cancer/Oncology',
		'Bowel',
		'Breast',
		'Genetics',
		'Gynaecology',
		'Head neck and thyroid',
		'Lung',
		'Cardiology',
		'Endocrinology',
		'Mental Health',
		'Counsellor',
		'Psychologist',
		'Neurology/Neurosurgery',
	];

	useEffect(() => {
		// If a user removes their chosen services, reset to show the full data
		if (!chosenServices.length) {
			setFilteredEntityData(entityData);
		}
	}, [chosenServices]);

	const availableTags = useMemo(() => {
		const allTags = new Set(
			entityData.flatMap((entity) => entity.entity_with_services.booking_entity.tags.split(', '))
		);
		return Array.from(allTags)
			.filter((tag) =>
				type === 'specialists' ? specialistTagButtons.includes(tag) : tagButtons.includes(tag)
			)
			.sort((a, b) =>
				type === 'specialists'
					? specialistTagButtons.indexOf(a) - specialistTagButtons.indexOf(b)
					: tagButtons.indexOf(a) - tagButtons.indexOf(b)
			);
	}, [entityData, type]);

	const handleTagClick = (tag: string) => {
		const updatedTags = selectedTags.includes(tag)
			? selectedTags.filter((t) => t !== tag)
			: [...selectedTags, tag];

		setSelectedTags(updatedTags);

		const filteredEntityData = entityData.filter((entity) => {
			const entityTags = entity.entity_with_services.booking_entity.tags.split(', ');
			return updatedTags.every((tag) => entityTags.includes(tag));
		});

		setFilteredEntityData(filteredEntityData);

		if (!isListingPage) {
			const queryParams = new URLSearchParams();

			if (chosenServices && chosenServices.length > 0) {
				const serviceNames = chosenServices.map((service) => service.value);
				queryParams.set('services', serviceNames.join(','));
			}

			if (updatedTags.length > 0) {
				queryParams.set('tags', updatedTags.join(','));
			}

			if (selectedLocation) {
				queryParams.set('location', selectedLocation);
			}

			if (selectedProvider) {
				queryParams.set('provider', selectedProvider.value);
			}

			const queryString = queryParams.toString();
			const ListingsPageUrl = currentPageData
				? `/${currentPageData.type}${queryString ? `?${queryString}` : ''}`
				: '';
			navigate(ListingsPageUrl);
		}
	};

	const handleLocationChange = debounce((value: string) => {
		setSelectedLocation(value);
	}, 500);

	const uniqueServices = new Set<string>();

	const servicesOptions: TagOption[] = entityData
		.flatMap((entity) => {
			const entityTags = entity.entity_with_services.booking_entity.tags || '';

			// Check if the entity matches the current type
			const matchesType =
				(type === 'wellbeing' && entityTags.includes('Wellbeing')) ||
				(type === 'mental-health' && entityTags.includes('Mental Health')) ||
				(type === 'international-treatments' && entityTags.includes('International Treatments')) ||
				(type === 'imaging' && entityTags.includes('Imaging')) ||
				(type !== 'wellbeing' && type !== 'mental-health' && type !== 'international-treatments');

			if (!matchesType || !entity.entity_with_services.available_services) {
				return [];
			}

			const filteredServices = entity.entity_with_services.available_services
				.filter((service: { service: { service_name: string } }) => service?.service)
				.map((service: { service: { service_name: string } }) => ({
					value: service.service?.service_name || '',
					label: service.service?.service_name || '',
				}))
				.filter((service: { value: string }) => {
					if (!uniqueServices.has(service.value)) {
						uniqueServices.add(service.value);
						return true;
					}
					return false;
				});
			return filteredServices;
		})
		.sort((a, b) => a.value.localeCompare(b.value));

	const specialistsOptions = [
		{
			label: 'Cancer/Oncology',
			options: [
				{ value: 'Bowel', label: 'Bowel' },
				{ value: 'Breast', label: 'Breast' },
				{ value: 'Genetics', label: 'Genetics' },
				{ value: 'Gynaecology', label: 'Gynaecology' },
				{ value: 'Head neck and thyroid', label: 'Head neck and thyroid' },
				{ value: 'Lung', label: 'Lung' },
			],
		},
		{
			label: 'Cardiology',
			options: [{ value: 'Cardiology', label: 'Cardiology' }],
		},
		{
			label: 'Endocrinology',
			options: [{ value: 'Endocrinology', label: 'Endocrinology' }],
		},
		{
			label: 'Mental Health',
			options: [
				{ value: 'Counsellor', label: 'Counsellor' },
				{ value: 'Psychologist', label: 'Psychologist' },
			],
		},
		{
			label: 'Neurology/Neurosurgery',
			options: [{ value: 'Neurology/Neurosurgery', label: 'Neurology/Neurosurgery' }],
		},
	];

	const [providerOptions, setProviderOptions] = useState<TagOption[]>([]);

	useEffect(() => {
		if (events && providerOptions.length === 0) {
			const uniqueProviders = Array.from(new Set(events.map((event) => event.event_organiser)));
			setProviderOptions(
				uniqueProviders.map((provider) => ({
					value: provider,
					label: provider,
				}))
			);
		}
	}, [events]); // Only run on mount, and do nothing if `providerOptions` is already set

	const dateSortOptions: TagOption[] = [
		{ value: 'today', label: "What's On Today" },
		{ value: 'next7days', label: "What's On In The Next 7 Days" },
		{ value: 'next30days', label: "What's On In The Next 30 Days" },
	];

	Geocode.setApiKey(config.REACT_APP_GOOGLE_GEOCODING_API_KEY);
	Geocode.setLanguage('en');
	Geocode.setRegion('gb');
	Geocode.enableDebug();

	const getCoordinatesFromAddress = async (address: string): Promise<Coords | null> => {
		try {
			const response = await Geocode.fromAddress(address);
			const { lat, lng } = response.results[0].geometry.location;
			return { lat, lng };
		} catch (error) {
			console.error('Error fetching coordinates:', error);
			return null;
		}
	};

	const calculateDistance = (pointA: Coords, pointB: Coords): number => {
		const rad = (x: number) => (x * Math.PI) / 180;
		const R = 6371; // Earth's mean radius in kilometers
		const dLat = rad(pointB.lat - pointA.lat);
		const dLong = rad(pointB.lng - pointA.lng);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(rad(pointA.lat)) *
				Math.cos(rad(pointB.lat)) *
				Math.sin(dLong / 2) *
				Math.sin(dLong / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c; // distance in kilometers
	};

	const handleToggleFormOrder = (value: string) => {
		if (value) {
			setFormOrder(value);
			return;
		} else {
			setFormOrder(
				formOrder === 'serviceLocationDate' ? 'locationServiceDate' : 'serviceLocationDate'
			);
		}
	};

	type Service = {
		service_name: string;
	};

	type AvailableService = {
		service: Service;
	};

	type EntityWithServices = {
		booking_entity: {
			tags: string;
			latitude: number;
			longitude: number;
		};
		available_services: AvailableService[];
	};

	type EntityData = {
		entity_with_services: EntityWithServices;
	};

	const handleSearch = async (searchData: {
		selectedProvider?: SingleValue<TagOption>;
		selectedDateSort?: SingleValue<TagOption>;
	}) => {
		try {
			if (searchData.selectedProvider) {
				const providerName = searchData.selectedProvider.value;
				const queryParams = new URLSearchParams({
					providerName,
					sortOrder: 'asc',
				});

				const response = await getRequest('/events', queryParams);

				setEvents(response.events);
			} else if (searchData.selectedDateSort) {
				const dateRange = searchData.selectedDateSort.value;
				const queryParams = new URLSearchParams({
					dateRange,
					sortOrder: 'asc',
				});

				const response = await getRequest('/events', queryParams);

				setEvents(response.events);
			} else {
				console.error('No provider selected');
			}
		} catch (error) {
			console.error('Error during search:', error);
		}
	};

	useEffect(() => {
		if (isListingPage) {
			const queryParams = new URLSearchParams(location.search);
			const servicesParam = queryParams.get('services')?.split(',');
			const tagsParam = queryParams.get('tags')?.split(',');
			const locationParam = queryParams.get('location');
			const providerParam = queryParams.get('provider');
			const dateSortParam = queryParams.get('dateSort');

			if (servicesParam && servicesParam.length > 0) {
				const initialServices = servicesParam.map((service) => ({
					value: service,
					label: service,
				}));
				setChosenServices(initialServices);
			}

			setSelectedTags(tagsParam || []);
			setSelectedLocation(locationParam || '');
			setSelectedProvider(providerParam ? { value: providerParam, label: providerParam } : null);
			setSelectedDateSort(
				dateSortParam
					? {
							value: dateSortParam,
							label: dateSortParam === 'soonest' ? 'Soonest date' : 'Furthest date',
						}
					: null
			);
		}
	}, [location.search, isListingPage]);

	useEffect(() => {
		setSelectedTags([]);
		setChosenServices([]);
		setSelectedLocation('');
		setSelectedProvider(null);
		setSelectedDateSort(null);
	}, []);

	const multiSelectStyle: StylesConfig<TagOption, true> = {
		control: (provided) => ({
			...provided,
			border: '2px solid #eaedff',
			borderRadius: '12px',
			minHeight: '60px',
			padding: '0 20px',
			width: '100%',
			background: '#fff',
			marginBottom: '2px',
		}),
		valueContainer: (provided) => ({
			...provided,
			minHeight: '46px',
			padding: '6px 8px',
			alignItems: 'center',
		}),
		input: (provided) => ({
			...provided,
			margin: 0,
			paddingTop: '0',
			paddingBottom: '0',
			lineHeight: '10px',
			color: '#555',
		}),
		placeholder: (provided) => ({
			...provided,
			lineHeight: '44px',
			color: '#555',
		}),
		multiValue: (provided) => ({
			...provided,
			background: '#e8eaf1',
		}),
	};

	const singleSelectStyle: StylesConfig<TagOption, false> = {
		control: (provided) => ({
			...provided,
			border: '2px solid #eaedff',
			borderRadius: '12px',
			minHeight: '60px',
			padding: '0 20px',
			width: '100%',
			background: '#fff',
			marginBottom: '2px',
		}),
		valueContainer: (provided) => ({
			...provided,
			minHeight: '46px',
			padding: '6px 8px',
			alignItems: 'center',
		}),
		input: (provided) => ({
			...provided,
			margin: 0,
			paddingTop: '0',
			paddingBottom: '0',
			lineHeight: '10px',
			color: '#555',
		}),
		placeholder: (provided) => ({
			...provided,
			lineHeight: '44px',
			color: '#555',
		}),
		multiValue: (provided) => ({
			...provided,
			background: '#e8eaf1',
		}),
	};

	const formatGroupLabel = (data: GroupBase<TagOption>) => (
		<div style={{ fontWeight: 'bold', color: '#999', marginBottom: '10px' }}>{data.label}</div>
	);

	return (
		<div className="hero-bg">
			<div className="container">
				<div className="row py-3 py-lg-5 pb-2 pb-lg-5 flex-column-reverse flex-lg-row">
					<div className="col-lg-5 mx-auto">
						<div>
							<div
								className="px-5 pb-4 pt-4"
								style={{
									backgroundColor: 'rgb(255, 255, 255)',
									padding: 0,
									paddingBottom: '18px',
									borderRadius: '8px',
									border: '1px solid rgba(203, 205, 215, 0.231372549)',
								}}
							>
								<div className="d-flex text-center mb-2">
									{/* Display "By Service" if type is not group-testing */}
									{type !== 'group-testing' && (
										<div
											className="col-6 py-2 px-2"
											style={{ borderRight: '1px solid #ccc' }}
											role="button"
											onClick={() => handleToggleFormOrder('serviceLocation')}
										>
											<h6
												style={
													formOrder === 'serviceLocation'
														? { borderBottom: '2px solid #2779a3', display: 'inline' }
														: { display: 'inline' }
												}
											>
												By Service
											</h6>
										</div>
									)}

									{/* Display "By Date" only if type is group-testing */}
									{type === 'group-testing' && (
										<div
											className="col-6 py-2 px-2"
											style={{ borderRight: '1px solid #ccc' }}
											role="button"
											onClick={() => handleToggleFormOrder('dateLocation')}
										>
											<h6
												style={
													formOrder === 'dateLocation'
														? { borderBottom: '2px solid #2779a3', display: 'inline' }
														: { display: 'inline' }
												}
											>
												By Date
											</h6>
										</div>
									)}

									{/* Display "By Provider" only if type is group-testing */}
									{type === 'group-testing' && (
										<div
											className="col-6 py-2 px-2"
											role="button"
											onClick={() => handleToggleFormOrder('providerLocation')}
										>
											<h6
												style={
													formOrder === 'providerLocation'
														? { borderBottom: '2px solid #2779a3', display: 'inline' }
														: { display: 'inline' }
												}
											>
												By Provider
											</h6>
										</div>
									)}

									{/* Display "By Location" only if type is NOT group-testing */}
									{type !== 'group-testing' && (
										<div
											className="col-6 py-2 px-2"
											role="button"
											onClick={() => handleToggleFormOrder('locationService')}
										>
											<h6
												style={
													formOrder !== 'serviceLocation' &&
													formOrder !== 'providerLocation' &&
													formOrder !== 'dateLocation'
														? { borderBottom: '2px solid #2779a3', display: 'inline' }
														: { display: 'inline' }
												}
											>
												By Location
											</h6>
										</div>
									)}
								</div>

								{/* Dynamic form fields based on formOrder */}
								{formOrder === 'serviceLocation' ? (
									<>
										<Select
											components={animatedComponents}
											options={type === 'specialists' ? specialistsOptions : servicesOptions}
											value={chosenServices}
											onChange={(selected) => setChosenServices(selected as MultiValue<TagOption>)}
											placeholder="Search for services"
											className="mb-3 service-search"
											isMulti
											styles={multiSelectStyle}
											formatGroupLabel={formatGroupLabel}
										/>
										<input
											type="text"
											placeholder={currentPageData?.locationPlaceholder || 'Enter postcode or area'}
											className="mb-3"
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleLocationChange(e.target.value)
											}
										/>
									</>
								) : formOrder === 'dateLocation' ? (
									<>
										<Select
											id="dateSort"
											options={dateSortOptions}
											value={selectedDateSort}
											onChange={(selected: SingleValue<TagOption>) => setSelectedDateSort(selected)}
											placeholder="Search by date"
											className="mb-3 service-search"
											styles={singleSelectStyle}
										/>

										{/* <input
											type="text"
											placeholder={currentPageData?.locationPlaceholder || 'Enter postcode or area'}
											className="mb-3"
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleLocationChange(e.target.value)
											}
										/> */}
									</>
								) : formOrder === 'providerLocation' ? (
									<>
										<Select
											components={animatedComponents}
											options={providerOptions}
											value={selectedProvider}
											onChange={(selected) =>
												setSelectedProvider(selected as SingleValue<TagOption>)
											}
											placeholder="Search for providers"
											className="mb-3 service-search"
											styles={singleSelectStyle}
										/>

										{/* <input
											type="text"
											placeholder={currentPageData?.locationPlaceholder || 'Enter postcode or area'}
											className="mb-3"
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleLocationChange(e.target.value)
											}
										/> */}
									</>
								) : (
									<>
										<input
											type="text"
											placeholder={currentPageData?.locationPlaceholder || 'Enter postcode or area'}
											className="mb-3"
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleLocationChange(e.target.value)
											}
										/>
										<Select
											components={animatedComponents}
											options={type === 'specialists' ? specialistsOptions : servicesOptions}
											value={chosenServices}
											onChange={(selected) => setChosenServices(selected as MultiValue<TagOption>)}
											placeholder="Search for services"
											className="mb-3 service-search"
											isMulti
											styles={multiSelectStyle}
											formatGroupLabel={formatGroupLabel}
										/>
									</>
								)}
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();

										// Handle the search based on the current formOrder
										if (formOrder === 'providerLocation') {
											if (selectedProvider) {
												// Pass the selected provider to the search function
												handleSearch({ selectedProvider });
											} else {
												alert('Please select a provider.');
											}
										} else if (formOrder === 'dateLocation') {
											if (selectedDateSort) {
												// Pass the selected date to the search function
												handleSearch({ selectedDateSort });
											} else {
												alert('Please select a date.');
											}
										}
									}}
									style={{ textAlign: 'right', display: 'block' }}
									className="mb-2"
								>
									<Button label="Search" variant="primary" fullWidth />
								</a>
							</div>
						</div>
					</div>
					<div className="col-lg-7 mx-auto align-self-center">
						<div className="pt-4 pt-lg-0 ps-lg-3">
							{currentPageData && <h3>{currentPageData.heroTitle}</h3>}
							{currentPageData?.heroParagraphs &&
								currentPageData.heroParagraphs.map((paragraph, index) => (
									<p key={index}>{paragraph}</p>
								))}
							{currentPageData?.heroTitle === 'International Treatments' && (
								<p>
									If you are unsure of anything, please{' '}
									<a
										href="https://forms.office.com/e/5K9t3bSDBG"
										target="_blank"
										rel="noopener noreferrer"
									>
										<u>contact us</u>
									</a>{' '}
									and we will respond within 1 business day.
								</p>
							)}
							{currentPageData?.heroTitle === 'Imaging' && (
								<p>
									If you are unsure of anything, please{' '}
									<a
										href="https://forms.office.com/e/z2KGAPqGmn"
										target="_blank"
										rel="noopener noreferrer"
									>
										<u>contact us</u>
									</a>{' '}
									and we will respond within 1 business day.
								</p>
							)}
						</div>
					</div>
				</div>
				{isListingPage ? (
					<div className="pb-4">
						{availableTags.map((tag) => (
							<button
								key={tag}
								className={`hero-tag-btn ${selectedTags.includes(tag) ? 'selected' : ''}`}
								type="button"
								onClick={() => handleTagClick(tag)}
							>
								{tag}
							</button>
						))}
					</div>
				) : (
					<div className="pb-1"></div>
				)}
			</div>
		</div>
	);
};

export default Hero;

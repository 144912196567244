import React, { useState, useContext } from 'react';
import './Card.scss';
import { BookingContext, Button } from '@sirkka-health/booking-system-ui';
import { useNavigate } from 'react-router-dom';
import ReadMore from '../ReadMore';

export default function Card({ type, entityItem, onOpenModal, onHover, onLeave }) {
	const [isHovered, setIsHovered] = useState(false);
	const [showAllServices, setShowAllServices] = useState(false);

	const { bookingData, setBookingData } = useContext(BookingContext);
	const navigate = useNavigate();

	const bookingEntity = entityItem.entity_with_services.booking_entity;
	const EntityUserMetadata = entityItem.entity_with_services.booking_user_metadata;
	const availableServices = entityItem.entity_with_services.available_services;

	const handleMouseEnter = () => {
		onHover(bookingEntity.booking_entity_id);
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		onLeave();
		setIsHovered(false);
	};

	const handleVirtualAppointment = (service) => {
		const bookingId = Date.now().toString();

		setBookingData([
			...bookingData,
			{
				booking: {
					bookingId,
					locationId: 5,
					clinicianId: service.virtualAppointment.clinicianId,
					bookingName: `${service.virtualAppointment.clinicianName} - Virtual Physiotherapy`,
					bookingProduct: service.virtualAppointment.clinicianId,
					appointmentLocation: 'Virtual',
					appointmentRegion: 'Virtual',
					appointmentDate: '',
					appointmentTime: '',
					appointmentNotes: '',
					appointmentFor: '',
					virtualOrPhysical: 'virtual',
					sampleConsent: '',
					termsconditions: '',
					completed: '',
				},
				clinician: {
					id: service.virtualAppointment.clinicianId,
					name: service.virtualAppointment.clinicianName,
					email: service.virtualAppointment.clinicianName,
				},
				customer: {
					nameTitle: '',
					firstName: '',
					lastName: '',
					gender: '',
					dateOfBirth: '',
					phoneNumber: '',
					company: '',
					occupation: '',
					address_line_1: '',
					address_line_2: '',
					city: '',
					postcode: '',
					country: '',
					address_type: 'home',
				},
				postal: {
					postageAddressLine1: '',
					postageAddressLine2: '',
					postageCity: '',
					postagePostcode: '',
					postageCountry: '',
					postageSelection: 'yes',
				},
				service: {
					serviceName: `Virtual Physiotherapy with ${service.virtualAppointment.clinicianName}`,
					serviceId: service.virtualAppointment.clinicianId,
					serviceType: 'virtualWellbeing',
					emailTemplate: 'virtualGP',
					regionId: 5,
					price: service.virtualAppointment.price,
					icon: service.virtualAppointment.icon,
					apptStackable: false,
					apptDuration: 30,
				},
				combineBooking: '',
				entity: {
					entityId: bookingEntity.booking_entity_id,
				},
			},
		]);

		navigate('/cart');
	};

	const handleOpenModal = (service, bookingEntity) => {
		onOpenModal(service, bookingEntity);
	};

	const renderServiceButton = (service) => {
		if (service.bookable && service.service_code) {
			return (
				<Button label="Book" onClick={() => handleOpenModal(service, bookingEntity)} fullWidth />
			);
		} else if (service.redirect_url) {
			return <Button label="Details" onClick={() => window.open(service.redirect_url)} fullWidth />;
		} else {
			return service.service_price ? (
				<p className="mb-0">
					from{' '}
					{new Intl.NumberFormat('en-GB', {
						style: 'currency',
						currency: 'GBP',
					}).format(service.service_price / 100)}
				</p>
			) : null;
		}

		// else if (service.virtualAppointment) {
		// 	const label = service.service_price ? `Book ${new Intl.NumberFormat('en-GB', {
		// 				style: 'currency',
		// 				currency: 'GBP',
		// 		  }).format(service.service_price / 100)}`
		// 		: 'Book';
		// 	return <Button label={label} onClick={() => handleVirtualAppointment(service)} fullWidth />;
		// }
	};

	const cardStyle = {
		backgroundColor: 'rgb(255, 255, 255)',
		padding: 0,
		paddingBottom: '18px',
		borderRadius: '8px',
		border: '1px solid rgba(203, 205, 215, 0.231372549)',
		boxShadow: isHovered ? '0px 2px 4px rgba(0, 0, 0, 0.2)' : 'none',
	};

	const toggleServices = () => setShowAllServices(!showAllServices);

	return (
		<div
			className="px-3 py-3 mb-3"
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="d-flex flex-column flex-md-row card-content-container">
				<div className="col-12 col-sm-12 col-md-5 col-xl-4">
					<div className="image-container">
						<img src={bookingEntity.image_url} alt={bookingEntity.display_name} />
					</div>
				</div>
				<div className="col-12 col-sm-12 col-md-7 col-xl-8 ps-sm-3 pt-4 pt-md-0">
					<div className="d-flex align-items-center mb-15">
						<div className="col-9">
							<h4 className="mb-0">{bookingEntity.display_name}</h4>
						</div>
						<div className="col-3">
							{bookingEntity.website_url && (
								<a href={bookingEntity.website_url} target="_blank" rel="noopener noreferrer">
									<Button
										label={bookingEntity.website_url.startsWith('mailto') ? 'Email' : 'Website'}
										fullWidth
										variant="border"
									/>
								</a>
							)}
						</div>
					</div>
					<ReadMore content={bookingEntity.description} />
				</div>
			</div>
			{availableServices &&
				availableServices.length > 0 &&
				availableServices.every((service) => service !== null) && (
					<div>
						{availableServices
							.slice(0, showAllServices ? availableServices.length : 3)
							.map((serviceItem, index) => (
								<div className="card-service d-flex px-3 mt-3" key={index}>
									<div className="col-9 col-lg-10">
										<h6 style={{ fontSize: '16px' }}>{serviceItem.service.service_name}</h6>

										<p style={{ marginTop: '3px' }} className="mb-0 pe-3">
											{serviceItem.service.description}
										</p>
									</div>
									<div className="col-3 col-lg-2 justify-content-end text-end align-self-center">
										{renderServiceButton(serviceItem.service)}
									</div>
								</div>
							))}
						{availableServices.length > 3 && (
							<button
								className={`view-more-button pb-2 ${showAllServices ? 'expanded' : ''}`}
								onClick={toggleServices}
							>
								{showAllServices ? 'View Less' : 'View all'}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<polyline points="6 9 12 15 18 9"></polyline>
								</svg>
							</button>
						)}
					</div>
				)}
		</div>
	);
}

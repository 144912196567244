const bookingObject = (booking, entity = null, service, customerDetails, clinician) => {
	return {
		eventId: booking.eventId || null,
		isLocationOnline: booking.appointmentLocation === 'Virtual',
		serviceId: service.serviceId,
		emailTemplate: service.emailTemplate,
		serviceName: service.serviceName,
		serviceType: service.serviceType,
		duration: 'PT15M',
		nhsNumber: booking.nhsNumber || null,
		priceType: 'fixedPrice',
		price: service.price / 100,
		serviceNotes: booking.appointmentNotes || '',
		clinicianId: booking.clinicianId || null,
		clinicianName: clinician ? clinician.name : null,
		doctorId: booking.doctorId || null,
		doctorName: null,
		referrer: booking.referrer || null,
		startDateTime: {
			dateTime: booking.startDateTime ? booking.startDateTime : null,
			timeZone: 'Europe/London',
		},
		endDateTime: {
			dateTime: booking.endDateTime ? booking.endDateTime : null,
			timeZone: 'Europe/London',
		},
		serviceLocation: {
			displayName: booking.appointmentLocation,
			regionId: service.regionId,
			locationId: booking.locationId,
			eventName: booking.event_name,
		},
		bookingEntity: {
			entityId: entity ? entity.entityId : null,
			entityName: entity ? entity.entityName : null,
		},
		reminders: [
			{
				offset: 'P2D',
				recipients: 'customer',
			},
		],
		customers: [
			{
				name: booking.bookingName,
				emailAddress: customerDetails.email,
				gender: customerDetails.gender,
				dateOfBirth: customerDetails.dateOfBirth,
				phone: customerDetails.phoneNumber || '',
				customerNotes: booking.appointmentNotes || '',
			},
		],
		accountTo: booking.accountTo || 'Sirkka Health',
	};
};

export default bookingObject;

import { BookingData, Location, ServiceData } from '../types';

const addToCart = (
	location: Location,
	matchedService: any,
	bookingEntityId: string,
	bookingData: BookingData[],
	setBookingData: (data: any[]) => void,
	serviceData: ServiceData | null,
	navigate: (path: string) => void,
	selectedEntityItem: any,
	clinician?: any,
	doctor?: any
) => {
	const bookingId = Date.now().toString();
	const appointmentAddress = location.city
		? [location.building_name, location.street, location.city, location.postcode]
				.filter(Boolean)
				.join(', ')
		: null;

	if (!serviceData) {
		console.error('Service data is not available.');
		return;
	}

	setBookingData([
		...bookingData,
		{
			booking: {
				bookingId,
				locationId: location.location_id,
				bookingProduct: serviceData.service.service_name,
				appointmentLocation: appointmentAddress || '',
				// appointmentRegion: location.city || 'Virtual',
				appointmentDate: null,
				appointmentTime: null,
				appointmentNotes: '',
				appointmentFor: 'Myself',
				completed: false,
				virtualOrPhysical: '',
				sampleConsent: '',
				termsconditions: '',
				bookingType: matchedService.type,
				referrer: '',
			},
			clinician: {
				id: clinician.user_id || null,
				name: clinician.full_name ? clinician.full_name : clinician.display_name,
				email: clinician.username ? clinician.username : clinician.email,
			},
			doctor: {
				id: doctor.user_id || null,
				name: doctor.full_name ? doctor.full_name : doctor.display_name,
				email: doctor.username ? doctor.username : doctor.email,
			},
			customer: {
				nameTitle: '',
				firstName: '',
				lastName: '',
				gender: '',
				dateOfBirth: '',
				phoneNumber: '',
				company: '',
				occupation: '',
				addressLine1: '',
				addressLine2: '',
				city: '',
				postcode: '',
				country: '',
				addressType: 'home',
			},
			postal: {
				postageAddressLine1: '',
				postageAddressLine2: '',
				postageCity: '',
				postagePostcode: '',
				postageCountry: '',
				postageSelection: 'no',
			},
			service: {
				serviceId: serviceData.service.service_code,
				serviceName: serviceData.service.service_name,
				regionId: location.location_category_id,
				icon: serviceData.content.service_icon,
				emailTemplate: serviceData.service.email_template,
				price: serviceData.service.service_price,
				apptStackable: serviceData.service.appt_stackable,
				apptDuration: serviceData.service.appt_duration,
				serviceType: serviceData.service.service_type,
			},
			entity: {
				entityId: bookingEntityId,
				entityName: selectedEntityItem?.display_name || '',
				entityImage: selectedEntityItem?.image_url || null,
			},
			combineBooking: '',
		},
	]);
	navigate('/cart');
};

const buildGeneralCartObject = (
	selectedLocation: any,
	selectedServiceItem: any,
	selectedEntityItem: any
) => {
	const bookingId = Date.now().toString();
	const appointmentAddress = selectedLocation.city
		? [
				selectedLocation.building_name,
				selectedLocation.street,
				selectedLocation.city,
				selectedLocation.postcode,
			]
				.filter(Boolean)
				.join(', ')
		: null;
	return {
		booking: {
			bookingId,
			locationId: selectedLocation.location_id || null,
			bookingProduct: selectedServiceItem.service_name,
			appointmentLocation: appointmentAddress,
			appointmentDate: null,
			appointmentTime: null,
			appointmentNotes: '',
			appointmentFor: 'Myself',
			completed: false,
			virtualOrPhysical: '',
			sampleConsent: '',
			termsconditions: '',
			referrer: '',
		},
		clinician: {
			id: selectedEntityItem.user_id || null,
			name: selectedEntityItem.full_name
				? selectedEntityItem.full_name
				: selectedEntityItem.display_name,
			email: selectedEntityItem.username ? selectedEntityItem.username : selectedEntityItem.email,
		},
		customer: {
			nameTitle: '',
			firstName: '',
			lastName: '',
			gender: '',
			dateOfBirth: '',
			phoneNumber: '',
			company: '',
			occupation: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			postcode: '',
			country: '',
			addressType: 'home',
		},
		postal: {
			postageAddressLine1: '',
			postageAddressLine2: '',
			postageCity: '',
			postagePostcode: '',
			postageCountry: '',
			postageSelection: 'no',
		},
		service: {
			serviceId: selectedServiceItem.service_code,
			serviceName: selectedServiceItem.service_name,
			regionId: selectedLocation.location_category_id,
			icon: selectedServiceItem.service_icon,
			emailTemplate: selectedServiceItem.email_template,
			price: selectedServiceItem.service_price,
			apptStackable: selectedServiceItem.appt_stackable,
			apptDuration: selectedServiceItem.appt_duration,
			serviceType: selectedServiceItem.service_type,
		},
		entity: {
			entityId: selectedEntityItem.booking_entity_id,
			entityName: selectedEntityItem?.display_name || '',
			entityImage: selectedEntityItem?.image_url || null,
		},
		combineBooking: '',
	};
};

// TODO: Build a helper function for event cart object
export { buildGeneralCartObject };
export default addToCart;
